import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../store/snackbar/actions';
import httpService from './HttpService';
import { logout } from '../store/auth/actions';
import { setLoader } from '../store/loader/actions';
import config from '../config';
import { setIsPausedProjectModalOpen } from '../store/helper/actions';

function ErrorHandler(props) {
  const dispatch = useDispatch();
  let spinnerTimer;
  let spinnerTimerCounter = 0;

  const handleRequestStart = async () => {
    httpService.addRequestInterceptor((callback) => {
      // start spinner
      if (spinnerTimer) clearTimeout(spinnerTimer);

      spinnerTimer = setTimeout(() => {
        if (spinnerTimerCounter === 0) return;
        dispatch(setLoader(true));
      }, config.spinnerTimeout);

      spinnerTimerCounter++;
      return callback;
    });
  };

  const handleError = async () => {
    httpService.addResponseInterceptors(
      (response) => {
        // stop spinner
        spinnerTimerCounter = Math.max(0, spinnerTimerCounter - 1);

        if (spinnerTimerCounter === 0) {
          if (spinnerTimer) clearTimeout(spinnerTimer);
          dispatch(setLoader(false));
        }

        return response;
      },
      (err) => {
        // stop spinner
        spinnerTimerCounter = Math.max(0, spinnerTimerCounter - 1);

        if (spinnerTimerCounter === 0) {
          if (spinnerTimer) clearTimeout(spinnerTimer);
          dispatch(setLoader(false));
        }
        err = err instanceof Error ? { err }?.err?.response : err;

        if (err) {
          if (err?.data instanceof ArrayBuffer) {
            err.message = JSON.parse(
              new TextDecoder().decode(err?.data)
            ).message;
            setSnackbar({
              open: true,
              severity: 'error',
              message: err.message,
            });
          }
        } else {
          setSnackbar({
            open: true,
            severity: 'error',
            message: 'Something went wrong!',
          });
        }

        if (err.status === 401) {
          dispatch(logout());
        }
        if (err.status === 400 || err.status === 500 || err.status === 404) {
          dispatch(
            setSnackbar({
              open: true,
              severity: 'error',
              message: err?.data?.message
                ? err?.data?.message
                : err?.message
                ? err?.message
                : 'Something went wrong!',
            })
          );
        }

        if (err.status === 403 && err.data?.type === 'pausedProject') {
          dispatch(setIsPausedProjectModalOpen(true));
        }
      }
    );
  };
  useEffect(() => {
    handleError();
    handleRequestStart();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return props.children;
}

export default ErrorHandler;
