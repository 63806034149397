import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCreateEventUrlValidSelector } from '../../store/respondentEvent/selectors';
import { journeySelector } from '../../store/journey/selectors';
import { useParams } from 'react-router-dom';
import { getCurrentRespondentEventSaga } from '../../store/respondentEvent/actions';
import CreateEventPage from './index';
import NotFound from '../NotFoundPage/index';
import { getJourneySaga } from '../../store/journey/actions';
import { makeSelectUser } from '../../store/auth/selectors';

const CreateEventPageWrapper = () => {
  const dispatch = useDispatch();
  const { respondentEventId, stepId } = useParams();

  const journey = useSelector(journeySelector());
  const isCreateEventUrlValid = useSelector(isCreateEventUrlValidSelector());
  const user = useSelector(makeSelectUser());

  useEffect(() => {
    if (user?.project?.status !== 'paused') {
      dispatch(getJourneySaga(user.journey_id));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user?.project?.status !== 'paused') {
      if (respondentEventId) {
        dispatch(getCurrentRespondentEventSaga(stepId, respondentEventId));
      } else {
        dispatch(getCurrentRespondentEventSaga(stepId));
      }
    }
  }, [journey]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isCreateEventUrlValid !== null) {
    if (isCreateEventUrlValid) {
      return <CreateEventPage />;
    } else {
      return <NotFound />;
    }
  } else {
    return <div />;
  }
};

export default CreateEventPageWrapper;
