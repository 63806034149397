import React from 'react';
import styles from './PausedProjectModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import CFDialog from '../../sharedComponents/Dialog/CFDialog';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/auth/actions';
import moment from 'moment';
import { setIsPausedProjectModalOpen } from '../../store/helper/actions';

const PausedProjectModal = ({ isOpen, date, isJourneyFinished }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onModalButtonClick = () => {
    if (isJourneyFinished) {
      dispatch(setIsPausedProjectModalOpen(false));
    } else {
      dispatch(logout());
    }
  };

  return (
    <CFDialog open={isOpen} size={'sm'}>
      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={styles.mainGrid}
        xs={12}
      >
        <p className={styles.title}>{'Project paused!'}</p>
        <p
          className={styles.subtitle}
        >{`Our Pathway tool is currently closed for maintenance and will be unavailable until ${
          date ? moment(date).format('MMM Do YYYY') : 'unknown date'
        }. We apologize for any inconvenience this may cause.`}</p>
        <p className={styles.subtitle}>
          {
            'If you need healthcare assistance during this time, please contact your healthcare provider directly.'
          }
        </p>
        <p className={styles.subtitle} style={{ padding: '0px 0 19px 0' }}>
          {'Thank you for your understanding and patience.'}
        </p>

        <button className={styles.buttonStyle} onClick={onModalButtonClick}>
          <span className={styles.buttonText}>
            {isJourneyFinished ? 'Close' : 'Logout'}
          </span>
        </button>
      </Grid>
    </CFDialog>
  );
};

export default PausedProjectModal;
