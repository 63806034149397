import React from 'react';
import { Dialog } from '@material-ui/core';
import { DialogContent } from '@mui/material';
import styles from '../Dialog/CFDialog.module.scss';

export default function CFDialog({
  children,
  open,
  handleClose,
  title,
  size = 'sm',
}) {
  return (
    <Dialog
      maxWidth={size}
      onClose={handleClose}
      open={open}
      disableEscapeKeyDown={true}
      disableScrollLock={true}
    >
      {title && <p className={styles.title}>{title}</p>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
