export const HELPER = '[Helper]';

export const GET_CURRENT_CREATE_ACCOUNT_STEP_SAGA = `${HELPER} GET_CURRENT_CREATE_ACCOUNT_STEP_SAGA`;
export const SET_CURRENT_CREATE_ACCOUNT_STEP = `${HELPER} SET_CURRENT_CREATE_ACCOUNT_STEP`;
export const SET_CREATE_ACCOUNT_QUESTION = `${HELPER} SET_CREATE_ACCOUNT_QUESTION`;

export const SET_IS_FEELINGS_DIALOG_OPEN = `${HELPER} SET_IS_FEELINGS_DIALOG_OPEN`;
export const SET_IS_FINAL_FEELINGS_DIALOG_OPEN = `${HELPER} SET_IS_FINAL_FEELINGS_DIALOG_OPEN`;
export const SET_IS_DOWNLOAD_JOURNEY_DIALOG_OPEN = `${HELPER} SET_IS_DOWNLOAD_JOURNEY_DIALOG_OPEN`;
export const SET_IS_PAUSED_PROJECT_MODAL_OPEN = `${HELPER} SET_IS_PAUSED_PROJECT_MODAL_OPEN`;
